var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-row',{staticClass:"ministry"},[_c('a-row',{staticClass:"content-title",attrs:{"type":"flex"}},[_c('h2',{staticClass:"flex-1"},[_vm._v(" "+_vm._s(_vm.$route.meta.title[_vm.$i18n.locale])+" ")])]),_c('a-table',{key:_vm.$route.fullPath,attrs:{"columns":_vm.columns,"data-source":_vm.list},scopedSlots:_vm._u([{key:"name",fn:function(item){return [_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push({
            name: 'commissions-events',
            params: {
              menu_id: item.id,
            },
          })}}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"operation",fn:function(item){return [_c('a-button',{staticClass:"edit-btn",attrs:{"type":"primary"},on:{"click":function($event){_vm.$router.push(
            {
              name: 'commissions-events',
              params: { menu_id: item.id },
            },
            function () { return null; }
          )}}},[_vm._v(" "+_vm._s(_vm.$t("Events"))+" ")]),_c('a-button',{staticClass:"edit-btn",attrs:{"type":"primary"},on:{"click":function($event){_vm.$router.push(
            {
              name: 'commissions-members',
              params: { menu_id: item.id },
            },
            function () { return null; }
          )}}},[_vm._v(" "+_vm._s(_vm.$t("CommissionsMembers"))+" ")]),_c('a-button',{staticClass:"edit-btn",attrs:{"type":"primary"},on:{"click":function($event){_vm.$router.push(
            {
              name: 'commissions-docs',
              params: { menu_id: item.id },
            },
            function () { return null; }
          )}}},[_vm._v(" "+_vm._s(_vm.$t("TableDoc"))+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }