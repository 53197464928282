<template>
  <a-row class="ministry">
    <a-row class="content-title" type="flex">
      <h2 class="flex-1">
        {{ $route.meta.title[$i18n.locale] }}
      </h2>

      <!-- <a-row class="flex-no__wrap" type="flex">
        <a-button type="primary" @click="openCreateModal">
          <a-icon type="plus" />{{ $t('Add') }}
        </a-button>
      </a-row> -->
    </a-row>
    <a-table :key="$route.fullPath" :columns="columns" :data-source="list">
      <template slot="name" slot-scope="item">
        <div
          style="cursor: pointer"
          @click="
            $router.push({
              name: 'commissions-events',
              params: {
                menu_id: item.id,
              },
            })
          "
        >
          {{ item.name }}
        </div>
      </template>
      <template slot="operation" slot-scope="item">
        <!-- <a-icon
          class="action-btns"
          type="edit"
          @click="
            $router.push(
              { 
                name: 'post-update',
                params: { id: item.id },
                query: { menu: $route.params.id }
              },
              () => null
            )
          "
        /> -->
        <a-button
          type="primary"
          class="edit-btn"
          @click="
            $router.push(
              {
                name: 'commissions-events',
                params: { menu_id: item.id },
              },
              () => null
            )
          "
        >
          {{ $t("Events") }}
          <!-- <a-icon type="edit" class="action-btns" /> -->
        </a-button>
        <a-button
          type="primary"
          class="edit-btn"
          @click="
            $router.push(
              {
                name: 'commissions-members',
                params: { menu_id: item.id },
              },
              () => null
            )
          "
        >
          {{ $t("CommissionsMembers") }}
        </a-button>
        <a-button
          type="primary"
          class="edit-btn"
          @click="
            $router.push(
              {
                name: 'commissions-docs',
                params: { menu_id: item.id },
              },
              () => null
            )
          "
        >
          {{ $t("TableDoc") }}
        </a-button>
        <!-- <a-popconfirm
          :cancel-text="$t('No')"
          :ok-text="$t('Yes')"
          :title="$t('APopconfirm')"
          @confirm="removeItem(item)"
        >
          <a-icon class="action-btns" style="margin: 0 10px" type="delete" />
        </a-popconfirm> -->
      </template>
    </a-table>
  </a-row>
</template>

<script>
// import searchInput from "@/components/wanted/search-input"

export default {
  data() {
    return {
      list: [],

      columns: [
        {
          title: this.$t("TagsColTitle"),
          key: "title",
          class: "wanted-fio-td",
          scopedSlots: {
            customRender: "name",
          },
        },
        {
          title: this.$t("UsersColTitle2"),
          key: "operation",
          fixed: "right",
          // width: "120px",
          scopedSlots: { customRender: "operation" },
        },
      ],
    };
  },
  computed: {
    getKey() {
      return this.langTab;
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      // this.loading = true;
      try {
        const { data } = await this.$store.dispatch("menu/fetch", {
          type: 1,
          parent_id: 10,
          category: "commissions",
        });
        data && this.$set(this, "list", data);
        // this.loading = false;
      } catch (err) {
        console.log(err);
      }
      // this.loading = false;
    },
  },
};
</script>
<style>
.wanted-search-input-wrapper {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 430px;
  flex: 0 0 430px;
  justify-content: space-around;
}
.ministry .ant-table-pagination.ant-pagination {
  display: none;
}
</style>
